/* personal background */

#life {
    padding: 4em 6em;
    margin-bottom: 12em;
    transition: all 500ms;
}



#life .title h1, #life .title h2{
    display: flex;
    justify-content: right;
    
}

.read-more {
    display: none;
}

.bio-container {
    display: flex;
    
    justify-content: space-between;
    position: relative;
    
}

.bio {
    position: relative;
    font-size: 1.4rem;
    width: 40%;
    color: #383838;
    
}



/* image gallary */


@mixin proportional-box($a: 1, $b: $a) {
	position: absolute;
    top: 0; left: calc(50vw - #{$a/$b/2*100vh});
 
  
  @media (max-aspect-ratio: #{$a}/#{$b}) {
    top: calc(50vh - #{$b/$a/2*100vw}); left: 0;
    
  }
}

.gallery {
    @include proportional-box(7, 3);
    position: relative;
    margin-top: -10em;
    margin-left: 0;
    width: 50%;
}

.personal {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    box-shadow: 10px 10px 13px -12px black;
}

.personal img {
    object-fit: cover;
    width: 100%;
    
}

/* there's probably a better way of doing this
but for the scope of this project it really doesn't matter */
.img-1 {
    width: 515px;
}

.img-2 {
    width: 415px;
    top: 12em;
    left: 20em;
}

.img-3 {
    width: 308px;
    top: 23em;
    left: 4em;
    z-index: 10;
}
/* cropping the image */
.img-3 img {
    width: 360px;
    
}

.img-4 {
    width: 291px;
    top: 35em;
    left: 20em;
}

.img-4 img{
    transform: scale(1.1);
}


 @media (max-width: 1524px) and (min-width: 980px ) {
   
    
    .img-1 {
        width: 515px;
    }
    
    .img-2 {
        top: 12em;
        left: 14vw;
    }
    
    .img-3 {
  
        top: 23em;
        left: 1vw;
        
    }

    .img-4 {
        width: 291px;
        top: 35em;
        left: 19vw;
    }
    
} 

@media (max-width: 1524px) and (min-width: 980px ) {
   
    
    #life {
        padding: 2em;
    }
    .gallery {
        transform: scale(.9);
        margin-left: -1em;
    }

    .img-1 {
        width: 515px;
    }
    
    .img-2 {
        top: 12em;
        left: 12vw;
    }
    
    .img-3 {
  
        top: 23em;
        left: 1vw;
        
    }

    .img- {
        width: 291px;
        top: 35em;
        left: 19vw;
    }
    
} 


@media (max-width: 980px) and (min-width: 740px){

    .bio {
        
        z-index: 11;
        p {
            border-radius: 20px;
            padding: 1em;
            background-color: rgba(255, 255, 255, 0.95)
        }
        @supports (backdrop-filter: blur(1rem)) {
            p {
                background-color: rgba(255, 255, 255, 0.89);
                backdrop-filter: blur(.6rem);
                
            }
        }
    }

    .gallery {
        width: 0;
        margin-top: 4em;
        min-height: 916px;
        height: 916px;
        top:0;
        position: relative;
        margin-bottom: -15em;
        margin-left: 6%;
        
    }

    #life {
        padding: 1em;
    }

    .img-1 {
        width: 340px;
       
    }
    
    .img-2 {
        top: 10em;
        left: 9vw;
        width: 300px;
    }
    
    .img-3 {
  
        top: 20em;
        left: 1vw;
        
    }

    .img-4 {
        width: 251px;
        top: 43em;
        left: 10vw;
    }
}

@media (max-width: 739px){

    #life .title h1, #life .title h2{
        display: flex;
        justify-content: center;
        
    }

    .bio-container {
        flex-direction: column-reverse;
        p {
            width: 15em;
            font-size: 1em;
        }

    }
    .bio {
        width: 100%;
        z-index: 11;
    }

    .gallery {
        width: 0;
        margin-top: 4em;
        min-height: 916px;
        height: 916px;
        top:0;
        position: relative;
        margin-bottom: -15em;
        
    }

    #life {
        padding: 1em;
    }

    .img-1 {
        width: 340px;
       
    }
    
    .img-2 {
        top: 10em;
        left: 9vw;
        width: 300px;
    }
    
    .img-3 {
  
        top: 20em;
        left: 1vw;
        
    }

    .img-4 {
        width: 251px;
        top: 43em;
        left: 20vw;
    }

    .read-container {
        display: flex;
        position: relative;
        overflow-y: hidden;
        height: 225px;
        justify-content: center;
        
    }

    .cover {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgb(233,233,233);
        background: linear-gradient(180deg, rgba(233,233,233,0) 0%, rgba(255,255,255,1) 100%);
    }

    .read-container[data-visible='true'] {
        height: 100%; 
        .cover {
            background: none !important;
        }
    }
    .pointer {
        transform: rotate(180deg) ;
    }
    .pointer[data-visible='true'] {
        transform: rotate(0deg) ;
    }
    
    #life{
        a {
            text-decoration: none;
        }
    }
    .read-more {
        
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        font-size: 1.2em !important;
        margin: 0 !important;
        padding: 0 !important;
        width: 100%;
        cursor: pointer;
    }

}