

.banner-container {
    margin: 0;
    position: relative;
    width: 100%;
    text-decoration: none;

    

    .back-container {
        color: white;
        position: absolute;
        font-weight: 500;
        font-size: 1.4rem;
        top: 1rem;
        left: 1rem;
        z-index: 4;
        height: 2rem;

        display: flex;
        align-items: center;
        cursor: pointer;

        
        
    }
    .arrow {
        transform: rotate(180deg);
    }
    a {
        position: absolute;
        text-decoration: none;
        right: 3rem;
        bottom: 2rem;
        font-size: 1.4rem;

        display: flex;
        align-items: center;

        color: cornflowerblue;
        .web-arrow {
            transform: rotate(0deg);
        }
    }
    .banner {
        
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 61%, 0 94%);
         clip-path: polygon(0 0, 100% 0, 100% 61%, 0 94%);

         
         max-height: 480px;
         max-width: 1280px;
        img {

            height: 100%;
            width: 100%;

            object-fit: cover;
        }
    }
    .fade{
        
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgb(255,255,255);
        background: linear-gradient(-6deg, rgba(255,255,255,1) 1%, rgba(0,212,255,0) 40%); 
        
    }
    .dark-fade {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        background: rgb(2,0,36);
        background: linear-gradient(155deg, rgba(2,0,36,1) 0%, rgba(0,212,255,0) 40%);
        opacity: 40%;
    }
    
    @media (max-width: 800px) {
        .back-container {
            font-size: 1.3rem;
            top: .5rem;
        }
        .website-link {
            bottom: 0;
            right: .5rem;
            font-size: 1.1rem;
        }
    }
}   
