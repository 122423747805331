/* contact section */

#contact {
    margin-bottom:  4em;
    margin-top: 0em;
    padding: 0em 6em;
    overflow:hidden;
}

.social-container {
    display: flex;
    gap: 3em;
    
}

.social p,.email a {
    font-weight: 500;
    font-size: 1.5em;
}

.social p:first-child{
    color:#7D7D7D;
}

.social-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    color: black;
    font-size: 3em;
    
}

.social-links a,.email a {
    text-decoration: none;
    padding: 0;
    color: inherit;
    transition: color 200ms;
    &:hover{
        color: cornflowerblue;
    }
}


@media (max-width: 980px) {

    #contact {
        margin-top: 0 !important;
        padding: 4em 2em;
    }

    .social-container {
        flex-direction: column;
        align-items: center;
        gap: 1em;
    }
    
    .social {
        width: 480px;
        min-width: 480px;
        border-right: none !important;
        &:first-child + ::before {
            content: "";
            display: block;
            width: 30px;
            height: 2px;
            background: #D8D8D8;
            margin-bottom: 1em;
        }
        
    }

    
}

@media (max-width: 535px) {

    #contact{
        margin-bottom: 0;
    }
    .social-links {
        display: flex;
        gap: 0 !important;
    }

    .social-container {
        gap: 0;
    }
    
    .social {
        padding: 0 !important;
        margin-left: 0 !important;
        min-width: 0px;
        width: 100%;
        margin-left: -3.5em;
    }

    .social {
        
        &:first-child {
            a {
                font-size: 1.2em;
            }
            margin-bottom: 1em;
        }
        &:last-child {
            padding-top: 0em;
        }
    }
}