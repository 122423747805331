/*navigation bar*/
nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    right: 0rem;
    top:0rem;
    z-index: 9999;
    @media (min-width: 981px) {
        width: 100%;
        @supports (backdrop-filter: blur(1rem)) {
            & {
                
                backdrop-filter: blur(.1rem);
            }
        }
    }
    
}



ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    list-style: none;
    padding: 0 1em;
    font-size: 1.3rem;
   
    
}

li {
    cursor: pointer;
    box-shadow: inset 0 -3px 0 -10 black;
    
    transition: box-shadow 200ms;
    &:hover {
        box-shadow: inset 0 -5px 0 black; 
        
    }

    a {
        display: block;
        text-decoration: none;
        color: inherit;
        font-size: 1.3em;
        padding: .4em .9em;
    }
}

@media (max-width: 980px) {
    nav {
        width: 100%;
        justify-content: center;
        right: 0;
    }
    ul {
        position: fixed;
        bottom: 0;
        border-top: 1px solid #e4e4e4 ;
        background-color: rgba(252, 252, 252, 0.99);

        width: 100%;
        font-size: 1.3em;
        
    }

    li {
        
        transition: box-shadow 400ms;
        &:hover {
            box-shadow: inset 0 -5px 0 black; 
            background: transparent;
        }
        a{
            font-size: 1em;
            padding: 1em .5em;
        }
    }

    @supports (backdrop-filter: blur(1rem)) {
        ul {
            background-color: rgba(252, 252, 252, 0.75);
            backdrop-filter: blur(.6rem);
        }
    }
}