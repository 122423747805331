/* Project section */

#portfolio {
    padding: 10em 6em;
}

.title h2 {
    font-weight: 500;
    font-size: 1.7em;
    color: #7D7D7D;
    margin-bottom: -1em;
}

.title h1 {
    font-weight: 500;
    font-size: 5em;
    
    margin-bottom: .5em;
}

.cards {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 3em;

    @media (min-width: 1250px) {
        grid-template-columns: repeat(3, auto);
    }
}

@media (max-width: 980px) {
    .title {
        text-align: center;
    }
    .title h1 {
        line-height: .9em;
        font-size: 3em;
    }

    .title h2 {
        font-size: 1.2em;
        white-space: nowrap;
        margin-bottom: 0;
    }

    .cards {
        
        position: relative;
        grid-template-columns: repeat(1,auto);
        width: 100%;
        justify-content: center;
        grid-row-gap: 1em;
    }

    #portfolio {
        padding: 2em 2em;
    }
}