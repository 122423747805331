@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500&display=swap');

.card-container {
    text-decoration: none !important;
  
}
.project-title {
    display: flex;
    flex-direction: column;
    padding: 1em 1em;
    max-width: calc(246px * 1.3);
    height: calc(124px * 1.3);
}

.showcase-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0) 35%, rgba(0,0,0,1) 66%, rgba(0,0,0,1) 100%);
    z-index: 2;
}

.card-container h2 {
    color: #A7A7A7;
    font-size: 1.7rem;
    font-weight: 300;
    margin-bottom: 0em;
    z-index: 3;
    text-decoration: none;
}


.card-container h1 {
    line-height: .9em;
    color: white;
    font-size: 2rem;
    font-weight: 500;
    z-index: 3;


}

.card-container {
    position: relative;
    background-color: black;
    width: auto;
    height: auto;
    max-width: calc(245px * 1.3);
    max-height: calc(358px * 1.3);
    overflow: hidden;
    border-radius: 25px;

    box-shadow: 10px 10px 13px -12px black;
    transition: transform 500ms, border-radius 250ms;
    min-width: 245px;


    &:hover {
        border-radius: 17px;
        box-shadow: 10px 10px 15px -12px black;
    }
    cursor: pointer;
    p {
        font-size: 1.1em;
        color: rgb(207, 207, 207);
        line-height: 2em;
        margin-top: .2em;
        z-index: 3;
    }

}

.card-cover {
    opacity: 0;
    padding: 1em;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.92);
    z-index: 13;

    color: white;

    h2 {
        font-weight: 300;
    }



    .long-disc {
        color:rgb(200, 200, 200)
    }

    .card-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: white;
    }
    transition: opacity 200ms;
    &:hover {
        opacity: 1;
    }

}

.project-container {
    overflow: hidden;
    position: relative;
    height: calc(245px * 1.3);
    width: calc(245px * 1.3);


}

.project-container img {
    object-fit: cover;
    width: 100%;
    transform: scale(1.1);
    position: absolute;
    bottom: 0;
    right: 0;
    transition: transform 250ms;

}

img.hovering {
    transform: scale(1.02);
}

@media (max-width: 980px) {
    .card-container {
        max-width: 245px;
    }

    .card-cover:hover{
        opacity: 0;
    }

}