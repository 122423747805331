@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500&display=swap');
@import './navbar';
@import './intro-section';
@import './project-section';
@import './personal-section';
@import './contact-section';



body {
    font-family: 'Poppins', sans-serif;
    background: rgb(227,227,227);
    background: linear-gradient(0deg, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 95%, rgba(221,221,221,1) 100%);
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
#page-container {
    
    
    overflow: hidden;
    
    width: 100%;
    height: 100%;
}
