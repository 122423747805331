@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');

.footer-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 2em 1em 1em;

    color: rgb(117, 117, 117);
    
    font-weight: 500;
    font-size: 1.2em;

    gap: 1em;

    p{
        display: flex;
        gap: 1em;
    }
    p:nth-child(1)::after {
        content: '';
        height: 2rem;
        width: 2px;
        background: rgb(177, 177, 177);

        display: inline-block;

    }

    a {
        text-decoration: underline rgb(196, 196, 196) 2px;
        color: inherit;
        
        transition: color 200ms;
        &:hover {
            color: cornflowerblue;
        }
    }
}

@media (max-width: 980px) {
    .footer-container{
        margin-bottom: 4em;

    }
    
}