#scroll-up{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: white;

    font-size: 5rem;
    transform: rotate(-90deg);

    cursor: pointer;
    
    & svg{
        background-color: #c5c5c5;
        border-radius: 100%;
        padding: .7rem;

        opacity: 80%;
        transition: 250ms;
        
        &.hidden {
            opacity: 0;
        }
    }
    
}