/* about */
#about {
    margin-top: 12em;
    padding: 1em;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.2em;
    
}

.face-card {
    
    
    border-radius: 30%;
    width: 345px;
    height: 345px;
    min-width: 344px;
    min-height: 345px;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 10px 10px 13px -12px black;
}

.face-card img {
    
    object-fit: cover;
    width: 100%;
    min-width: 100%;

}

.intro-text {
    
    height: 100%;
    max-width: 700px;
    
}

.intro-text h1 {
    font-size: 4em;
    font-weight: 400;
}

.intro-text p {
    font-weight: 300;
    font-size: 1.7rem;
    color: #626262;
    margin-top: -2rem;
    padding-left: .5rem;
}

.link-learn {

    text-decoration: none;
    display: flex;
    align-items: center;
    color: inherit;
    font-size: 1.7em;
    padding-left: .5rem;

    transition: color 100ms !important;
    &:hover {
        span {
            margin-right: .5rem;
        }
        color: cornflowerblue;
    }
}

.arrow {
    font-size: 1.3em;
    
}


#about *, #about{
    transition: margin 600ms, transform 500ms, width 500ms,;
}

@media (max-width: 980px) {
    #about {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
        gap: 0em;

        margin-top: 2em;
        padding: 0em;

        text-align: center;

    }

    .face-card {
        
        min-width: 215px;
        min-height: 210px;
        width: 0;
        height: 0;
        
    }

    .intro-text {
        transform: scale(.8);
        margin-left: 0;
        padding: 1em 2.4em;
        
    }

    .intro-text h1 {
        font-size: 3em;
        font-weight: 400;
        line-height: 1em;
    }
    
    .intro-text p{
        padding-top: 1em;
        font-size: 1.4em;
    }

    .link-learn {
        justify-content: center;
        font-size: 1.4em;
    }

    
}