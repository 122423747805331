.title-disc-container {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .divider {
        height: 7.5em;
        width: 2px;
        border-radius: 2px;
        background-color: #7D7D7D;
    }
    .info-widgets {
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        align-items: flex-start;
        justify-content: center;

        .info-text {
            display: flex;
            align-items: center;
            gap: 1rem;
            align-items: center;
            .info-icon {
                font-size: 2.5rem;
            }
            p {
                color: #7D7D7D;
                font-weight: 500;
                font-size: 1.5rem;
            }
        }
    }

    @media (max-width: 800px) {
        display: grid;
        .info-text:first-child {
            margin-top: 1.4rem;
        }
        .divider {
            height: 2px;
            width: 7.5em;
            border-radius: 2px;
            background-color: #7D7D7D;
        }
        .info-text {
            .info-icon {
                font-size: 2rem !important;
            }
            p {
                color: #7D7D7D;
                font-weight: 500;
                font-size: 1.3rem !important;
            }
        }
    }
   
}